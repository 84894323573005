<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient333" x1="1" x2="0.323" y1="-0.106" y2="0.898">
     <stop offset="0" stop-color="#f7a157"/>
     <stop offset="1" stop-color="#e81212"/>
    </linearGradient>
   </defs>
   <g id="Printerest" transform="translate(-450 -368)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(450 368)" width="60"/>
    <path d="M12.262.457C6.095.457,0,4.568,0,11.222c0,4.232,2.38,6.636,3.823,6.636.6,0,.938-1.659.938-2.128,0-.559-1.425-1.749-1.425-4.075A8.123,8.123,0,0,1,11.775,3.4C15.868,3.4,18.9,5.722,18.9,10c0,3.192-1.28,9.178-5.428,9.178a2.669,2.669,0,0,1-2.777-2.633c0-2.272,1.587-4.472,1.587-6.816,0-3.979-5.644-3.258-5.644,1.551a7.062,7.062,0,0,0,.577,3.047c-.829,3.57-2.525,8.89-2.525,12.568,0,1.136.162,2.254.27,3.39.2.228.1.2.415.09C8.4,26.225,8.295,25.414,9.665,19.986a4.875,4.875,0,0,0,4.165,2.164c6.383,0,9.251-6.221,9.251-11.829C23.081,4.352,17.924.457,12.262.457Z" data-name="Icon awesome-pinterest-p" id="Icon_awesome-pinterest-p" style="fill: url(#linear-gradient333);" transform="translate(468 382.543)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>